import React, { useEffect, useState, useCallback } from 'react';
import { getUserWithCompany, UserWithCompany } from '../api/api';
import { useAlerts } from '../alerts/alertContext';
import { useQuery } from '@tanstack/react-query';
import { Button, Input, Spinner } from '@nextui-org/react';
import { useNavigate, useParams } from 'react-router-dom';
import CommandBar from '../components/CommandBar';

const Profile = () => {
  const { addAlert } = useAlerts();
  const navigate = useNavigate();
  const { projectID } = useParams();
  const [isCommandBarOpen, setIsCommandBarOpen] = useState(false);

  const { data, error, isLoading } = useQuery<UserWithCompany>({
    queryKey: ['userWithCompany'],
    queryFn: () => getUserWithCompany(),
  });

  useEffect(() => {
    if (error) {
      addAlert({
        severity: 'error',
        message: error.message,
        timeout: 5,
      });
    }
  }, [error, addAlert]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
      event.preventDefault();
      setIsCommandBarOpen(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return (
    <div className="p-5 w-full h-full overflow-auto">
      <h1 className="text-2xl mb-12 font-medium text-page-title">Profile</h1>
      {isLoading && <Spinner size="lg" />}
      {data && (
        <div className="flex flex-col gap-8">
          <div className="flex flex-row gap-16 justify-center items-center">
            <img
              src={data.profile_picture}
              alt="Profile"
              className="w-32 rounded-full border-3 hover:border-profile-border transition-all ease-in"
            />
            <div className="flex flex-col gap-8">
              <Input
                isDisabled
                type="text"
                label="Name"
                defaultValue={data.name}
                classNames={{
                  base: 'max-w-xs',
                  inputWrapper: 'bg-disabled-input-background',
                }}
              />
              <Input
                isDisabled
                type="email"
                label="Email"
                defaultValue={data.email}
                classNames={{
                  base: 'max-w-xs',
                  inputWrapper: 'bg-disabled-input-background',
                }}
              />
            </div>
            <div className="flex flex-col gap-8">
              <Input
                isDisabled
                type="text"
                label="Status"
                defaultValue={data.status}
                classNames={{
                  base: 'max-w-xs',
                  inputWrapper: 'bg-disabled-input-background',
                }}
              />
              <Input
                isDisabled
                type="text"
                label="Role"
                defaultValue={data.role}
                classNames={{
                  base: 'max-w-xs',
                  inputWrapper: 'bg-disabled-input-background',
                }}
              />
            </div>
          </div>
          <div className="flex justify-center items-center">
            <Button
              size="md"
              className="w-48 font-semibold"
              color="danger"
              onPress={handleLogout}
            >
              Logout
            </Button>
          </div>
        </div>
      )}
      <CommandBar
        isOpen={isCommandBarOpen}
        onClose={() => setIsCommandBarOpen(false)}
        projectID={projectID!}
      />
    </div>
  );
};

export default Profile;
