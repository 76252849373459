import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { login } from '../api/auth';
import GoogleSigninButton from '../components/GoogleSigninButton';

const Login: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    if (token) {
      login(token).then(() => {
        queryClient.invalidateQueries({ queryKey: ['user'] });
        navigate('/projects');
      });
    } else {
      const token = localStorage.getItem('token');
      if (token) {
        navigate('/projects');
      }
    }
  }, [location, navigate, queryClient]);

  const handleGoogleSignIn = () => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/auth/login`;
  };

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen overflow-hidden px-12">
      <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[linear-gradient(to_right,#f0f0f0_1px,transparent_1px),linear-gradient(to_bottom,#f0f0f0_1px,transparent_1px)] bg-[size:6rem_4rem]"></div>
      <div
        className="px-12 py-12 bg-white backdrop-blur-sm z-10 rounded-lg border border-slate-300 max-w-[700px] w-full
                      shadow-lg hover:shadow-xl 
                      transition-all duration-300 ease-in-out
                      "
      >
        <div className="flex justify-center items-center font-bold text-2xl mb-4 text-center">
          Welcome to Katalog!
        </div>
        <div className="flex justify-center items-center pb-8 pt-4">
          <img src="/katalog_logo.png" className="w-[128px]" />
        </div>
        <div className="flex justify-center items-center text-lg mb-4 text-center">
          Log in to supercharge your data warehousing experience!
        </div>
        <div className="flex justify-center">
          <span className="relative inline-block overflow-hidden rounded-2xl p-[3px]">
            <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#dc2626_0%,#a3e635_50%,#dc2626_100%)]"></span>
            <GoogleSigninButton handleGoogleSignIn={handleGoogleSignIn} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
