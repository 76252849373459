import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import React from 'react';
import FileUploadDropzone from './FileUploadDropzone';

interface ServiceAccountSettingsProps {
  hasKey: boolean | undefined;
  formatDate(dateString: string | undefined): string;
  keyCreatedAt: string | undefined;
  onUploadModalOpen(): void;
  isUploadModalOpen: boolean;
  onUploadModalOpenChange(): void;
  handleModalClose(): void;
  handleFileSelect(file: File): void;
  uploadPending: boolean;
  handleFileUpload(): void;
  selectedFile: File | null;
}

const ServiceAccountSettings: React.FC<ServiceAccountSettingsProps> = ({
  hasKey,
  formatDate,
  keyCreatedAt,
  onUploadModalOpen,
  isUploadModalOpen,
  onUploadModalOpenChange,
  handleModalClose,
  handleFileSelect,
  uploadPending,
  handleFileUpload,
  selectedFile,
}) => {
  return (
    <div>
      <h3 className="text-lg text-setting-section-title font-medium">
        Service Account Credentials
      </h3>
      <Card className="w-full min-h-48">
        {hasKey ? (
          <CardBody className="flex flex-col justify-center items-center flex-grow gap-8 px-6 py-12">
            <img
              src={`${process.env.PUBLIC_URL}/check_icon.svg`}
              className="h-16"
            />
            <div className="text-service-account-text font-semibold text-center">
              You have already uploaded your service account key.
              <br />
              Last updated at: {formatDate(keyCreatedAt)}
            </div>
            <Button
              onPress={onUploadModalOpen}
              className="font-bold bg-button text-white hover:scale-105"
            >
              Upload New Credentials File
            </Button>
            <Modal
              isOpen={isUploadModalOpen}
              onOpenChange={onUploadModalOpenChange}
              onClose={handleModalClose}
              backdrop="blur"
            >
              <ModalContent>
                {() => (
                  <>
                    <ModalHeader className="flex flex-col gap-1 text-page-title">
                      Upload New Key File
                    </ModalHeader>
                    <ModalBody>
                      <FileUploadDropzone onFileSelect={handleFileSelect} />
                    </ModalBody>
                    <ModalFooter>
                      {!uploadPending ? (
                        <Button
                          className="w-full bg-button disabled:bg-disabled-button text-white"
                          onPress={handleFileUpload}
                          disabled={!selectedFile}
                        >
                          Upload File
                        </Button>
                      ) : (
                        <Button
                          className="w-full bg-button text-white"
                          isLoading
                        >
                          Uploading...
                        </Button>
                      )}
                    </ModalFooter>
                  </>
                )}
              </ModalContent>
            </Modal>
          </CardBody>
        ) : (
          <CardBody className="flex flex-col justify-center items-center flex-grow gap-8 px-6 py-12">
            <img
              src={`${process.env.PUBLIC_URL}/cross_icon.svg`}
              className="h-16"
            />
            <div className="text-service-account-text font-semibold">
              You have not uploaded a service account key yet.
            </div>
            <Button
              onPress={onUploadModalOpen}
              className="font-bold bg-button text-white hover:scale-105"
            >
              Upload Service Account Key
            </Button>
            <Modal
              isOpen={isUploadModalOpen}
              onOpenChange={onUploadModalOpenChange}
              onClose={handleModalClose}
              backdrop="blur"
            >
              <ModalContent>
                {() => (
                  <>
                    <ModalHeader className="flex flex-col gap-1 text-page-title">
                      Upload New Key File
                    </ModalHeader>
                    <ModalBody>
                      <FileUploadDropzone onFileSelect={handleFileSelect} />
                    </ModalBody>
                    <ModalFooter>
                      {!uploadPending ? (
                        <Button
                          className="w-full bg-button text-white disabled:bg-disabled-button"
                          onPress={handleFileUpload}
                          disabled={!selectedFile}
                        >
                          Upload File
                        </Button>
                      ) : (
                        <Button
                          className="w-full bg-button text-white"
                          isLoading
                        >
                          Uploading...
                        </Button>
                      )}
                    </ModalFooter>
                  </>
                )}
              </ModalContent>
            </Modal>
          </CardBody>
        )}
      </Card>
    </div>
  );
};

export default ServiceAccountSettings;
