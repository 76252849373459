import React, { useState, useCallback, useEffect } from 'react';
import { getSyncsWithChangelog, SyncWithChangelogs } from '../api/api';
import { BreadcrumbItem, Breadcrumbs, Card } from '@nextui-org/react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import CommandBar from '../components/CommandBar';

const Releases: React.FC = () => {
  const { projectID } = useParams();
  const navigate = useNavigate();
  const [isCommandBarOpen, setIsCommandBarOpen] = useState(false);
  const { data: syncs, error } = useQuery<SyncWithChangelogs[]>({
    queryKey: ['syncs', projectID],
    queryFn: () => getSyncsWithChangelog(projectID!),
    enabled: !!projectID,
  });

  const formatDate = (dateString: string) => {
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
      }
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    } catch (error) {
      console.error('Invalid date value:', error);
      return 'Invalid date';
    }
  };

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
      event.preventDefault();
      setIsCommandBarOpen(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return (
    <div className="p-5 w-full h-full overflow-auto">
      <h1 className="text-2xl font-medium text-page-title">Releases</h1>
      <Breadcrumbs size="lg" className="mb-4">
        <BreadcrumbItem href={`/${projectID}/releases`} className="opacity-50">
          Releases
        </BreadcrumbItem>
      </Breadcrumbs>
      {error instanceof Error && (
        <p className="text-red-500">{error.message}</p>
      )}
      {syncs?.length == 0 ? (
        <div className="h-64 w-full flex justify-center items-center text-center text-service-account-text align-middle">
          <span>
            There are no releases yet. Trigger a sync in the settings page to
            populate releases.
          </span>
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          {syncs?.map((sync) => {
            return (
              <Card
                className="bg-accordion-background hover:bg-accordion-item-hover px-8 py-4 shadow-none flex flex-row justify-between"
                key={sync.id}
                isPressable
                onPress={() => navigate(`/${projectID}/releases/${sync.id}`)}
              >
                <p>Release at {formatDate(sync.CreatedAt)}</p>
                <p className="font-bold text-zinc-400">
                  {sync.changelogs.length} Changes
                </p>
              </Card>
            );
          })}
        </div>
      )}
      <CommandBar
        isOpen={isCommandBarOpen}
        onClose={() => setIsCommandBarOpen(false)}
        projectID={projectID!}
      />
    </div>
  );
};

export default Releases;
