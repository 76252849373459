import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import Login from './pages/Login';
import Catalog from './pages/Catalog';
import Dashboard from './pages/Dashboard';
import Tables from './pages/Tables';
import Columns from './pages/Columns';
import Sidebar from './components/Sidebar';
import SidebarButton from './components/SidebarButton';
import { AnimatePresence } from 'framer-motion';
import TransitionWrapper from './components/TransitionWrapper';
import { getUserInfo, User } from './api/auth';
import Loading from './components/Loading';
import Settings from './pages/Settings';
import AlertsProvider from './alerts/alertContext';
import CreateCompany from './pages/CreateCompany';
import Projects from './pages/Projects';
import Profile from './pages/Profile';
import Releases from './pages/Releases';
import Release from './pages/Release';

const queryClient = new QueryClient();

const AuthGuard: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const {
    data: user,
    isLoading,
    isError,
  } = useQuery<User>({
    queryKey: ['user'],
    queryFn: getUserInfo,
    retry: false,
    staleTime: Infinity,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (isError || !user) {
    return <Navigate to="/login" />;
  }

  return children;
};

const AppLayout: React.FC = () => {
  const [selected, setSelected] = useState<string>(
    localStorage.getItem('theme') || 'light'
  );
  useEffect(() => {
    const selectedTheme = localStorage.getItem('theme');

    if (selectedTheme === 'light') {
      document.body.classList.add('light');
      document.body.classList.remove('dark');
    } else if (selectedTheme === 'dark') {
      document.body.classList.add('dark');
      document.body.classList.remove('light');
    } else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.body.classList.add('dark');
      document.body.classList.remove('light');
    } else {
      document.body.classList.add('light');
      document.body.classList.remove('dark');
    }
  }, [selected]);

  const { data: user } = useQuery<User>({
    queryKey: ['user'],
    queryFn: getUserInfo,
    retry: false,
    staleTime: Infinity,
  });

  const handleThemeSelect = (theme: string) => {
    localStorage.setItem('theme', theme);
    setSelected(theme);
  };

  return (
    <div className="flex h-screen">
      <div className="flex-grow">
        <AnimatePresence mode="wait">
          <Routes>
            <Route
              path="/login"
              element={
                <TransitionWrapper>
                  <Login />
                </TransitionWrapper>
              }
            />
            <Route
              path="/company/create"
              element={
                <TransitionWrapper>
                  <CreateCompany />
                </TransitionWrapper>
              }
            />
            <Route
              path="/projects"
              element={
                <AuthGuard>
                  <TransitionWrapper>
                    <Projects />
                  </TransitionWrapper>
                </AuthGuard>
              }
            />
            <Route
              path="/:projectID/catalog"
              element={
                <AuthGuard>
                  <div className="flex flex-row">
                    {user && (
                      <Sidebar
                        user={user}
                        handleThemeSelect={handleThemeSelect}
                        selected={selected}
                      >
                        <SidebarButton
                          text="Dashboard"
                          link="/dashboard"
                          iconSrc="dashboard_icon.svg"
                        />
                        <SidebarButton
                          text="Catalog"
                          link="/catalog"
                          iconSrc="catalog_icon.svg"
                        />
                        <SidebarButton
                          text="Settings"
                          link="/settings"
                          iconSrc="settings_icon.svg"
                        />
                        <SidebarButton
                          text="Releases"
                          link="/releases"
                          iconSrc="releases_icon.svg"
                        />
                      </Sidebar>
                    )}
                    <div className="flex-grow p-4 h-screen">
                      <TransitionWrapper>
                        <Catalog />
                      </TransitionWrapper>
                    </div>
                  </div>
                </AuthGuard>
              }
            />
            <Route
              path="/:projectID/dashboard"
              element={
                <AuthGuard>
                  <div className="flex flex-row">
                    {user && (
                      <Sidebar
                        user={user}
                        handleThemeSelect={handleThemeSelect}
                        selected={selected}
                      >
                        <SidebarButton
                          text="Dashboard"
                          link="/dashboard"
                          iconSrc="dashboard_icon.svg"
                        />
                        <SidebarButton
                          text="Catalog"
                          link="/catalog"
                          iconSrc="catalog_icon.svg"
                        />
                        <SidebarButton
                          text="Settings"
                          link="/settings"
                          iconSrc="settings_icon.svg"
                        />
                        <SidebarButton
                          text="Releases"
                          link="/releases"
                          iconSrc="releases_icon.svg"
                        />
                      </Sidebar>
                    )}
                    <div className="h-screen w-full p-4">
                      <TransitionWrapper>
                        <Dashboard />
                      </TransitionWrapper>
                    </div>
                  </div>
                </AuthGuard>
              }
            />
            <Route
              path="/:projectID/settings"
              element={
                <AuthGuard>
                  <div className="flex flex-row">
                    {user && (
                      <Sidebar
                        user={user}
                        handleThemeSelect={handleThemeSelect}
                        selected={selected}
                      >
                        <SidebarButton
                          text="Dashboard"
                          link="/dashboard"
                          iconSrc="dashboard_icon.svg"
                        />
                        <SidebarButton
                          text="Catalog"
                          link="/catalog"
                          iconSrc="catalog_icon.svg"
                        />
                        <SidebarButton
                          text="Settings"
                          link="/settings"
                          iconSrc="settings_icon.svg"
                        />
                        <SidebarButton
                          text="Releases"
                          link="/releases"
                          iconSrc="releases_icon.svg"
                        />
                      </Sidebar>
                    )}
                    <div className="flex-grow p-4 h-screen">
                      <TransitionWrapper>
                        <Settings />
                      </TransitionWrapper>
                    </div>
                  </div>
                </AuthGuard>
              }
            />
            <Route
              path="/:projectID/datasets/:datasetId"
              element={
                <AuthGuard>
                  <div className="flex flex-row">
                    {user && (
                      <Sidebar
                        user={user}
                        handleThemeSelect={handleThemeSelect}
                        selected={selected}
                      >
                        <SidebarButton
                          text="Dashboard"
                          link="/dashboard"
                          iconSrc="dashboard_icon.svg"
                        />
                        <SidebarButton
                          text="Catalog"
                          link="/catalog"
                          iconSrc="catalog_icon.svg"
                        />
                        <SidebarButton
                          text="Settings"
                          link="/settings"
                          iconSrc="settings_icon.svg"
                        />
                        <SidebarButton
                          text="Releases"
                          link="/releases"
                          iconSrc="releases_icon.svg"
                        />
                      </Sidebar>
                    )}
                    <div className="flex-grow p-4 h-screen">
                      <TransitionWrapper>
                        <Tables />
                      </TransitionWrapper>
                    </div>
                  </div>
                </AuthGuard>
              }
            />
            <Route
              path="/:projectID/tables/:tableId"
              element={
                <AuthGuard>
                  <div className="flex flex-row">
                    {user && (
                      <Sidebar
                        user={user}
                        handleThemeSelect={handleThemeSelect}
                        selected={selected}
                      >
                        <SidebarButton
                          text="Dashboard"
                          link="/dashboard"
                          iconSrc="dashboard_icon.svg"
                        />
                        <SidebarButton
                          text="Catalog"
                          link="/catalog"
                          iconSrc="catalog_icon.svg"
                        />
                        <SidebarButton
                          text="Settings"
                          link="/settings"
                          iconSrc="settings_icon.svg"
                        />
                        <SidebarButton
                          text="Releases"
                          link="/releases"
                          iconSrc="releases_icon.svg"
                        />
                      </Sidebar>
                    )}
                    <div className="flex-grow p-4 h-screen">
                      <TransitionWrapper>
                        <Columns />
                      </TransitionWrapper>
                    </div>
                  </div>
                </AuthGuard>
              }
            />
            <Route
              path="/:projectID/releases"
              element={
                <AuthGuard>
                  <div className="flex flex-row">
                    {user && (
                      <Sidebar
                        user={user}
                        handleThemeSelect={handleThemeSelect}
                        selected={selected}
                      >
                        <SidebarButton
                          text="Dashboard"
                          link="/dashboard"
                          iconSrc="dashboard_icon.svg"
                        />
                        <SidebarButton
                          text="Catalog"
                          link="/catalog"
                          iconSrc="catalog_icon.svg"
                        />
                        <SidebarButton
                          text="Settings"
                          link="/settings"
                          iconSrc="settings_icon.svg"
                        />
                        <SidebarButton
                          text="Releases"
                          link="/releases"
                          iconSrc="releases_icon.svg"
                        />
                      </Sidebar>
                    )}
                    <div className="flex-grow p-4 h-screen">
                      <TransitionWrapper>
                        <Releases />
                      </TransitionWrapper>
                    </div>
                  </div>
                </AuthGuard>
              }
            />
            <Route
              path="/:projectID/releases/:syncID"
              element={
                <AuthGuard>
                  <div className="flex flex-row">
                    {user && (
                      <Sidebar
                        user={user}
                        handleThemeSelect={handleThemeSelect}
                        selected={selected}
                      >
                        <SidebarButton
                          text="Dashboard"
                          link="/dashboard"
                          iconSrc="dashboard_icon.svg"
                        />
                        <SidebarButton
                          text="Catalog"
                          link="/catalog"
                          iconSrc="catalog_icon.svg"
                        />
                        <SidebarButton
                          text="Settings"
                          link="/settings"
                          iconSrc="settings_icon.svg"
                        />
                        <SidebarButton
                          text="Releases"
                          link="/releases"
                          iconSrc="releases_icon.svg"
                        />
                      </Sidebar>
                    )}
                    <div className="flex-grow p-4 h-screen">
                      <TransitionWrapper>
                        <Release />
                      </TransitionWrapper>
                    </div>
                  </div>
                </AuthGuard>
              }
            />
            <Route
              path="/:projectID/profile"
              element={
                <AuthGuard>
                  <div className="flex flex-row">
                    {user && (
                      <Sidebar
                        user={user}
                        handleThemeSelect={handleThemeSelect}
                        selected={selected}
                      >
                        <SidebarButton
                          text="Dashboard"
                          link="/dashboard"
                          iconSrc="dashboard_icon.svg"
                        />
                        <SidebarButton
                          text="Catalog"
                          link="/catalog"
                          iconSrc="catalog_icon.svg"
                        />
                        <SidebarButton
                          text="Settings"
                          link="/settings"
                          iconSrc="settings_icon.svg"
                        />
                        <SidebarButton
                          text="Releases"
                          link="/releases"
                          iconSrc="releases_icon.svg"
                        />
                      </Sidebar>
                    )}
                    <div className="flex-grow p-4 h-screen">
                      <TransitionWrapper>
                        <Profile />
                      </TransitionWrapper>
                    </div>
                  </div>
                </AuthGuard>
              }
            />
            <Route path="*" element={<Navigate to="/projects" />} />
          </Routes>
        </AnimatePresence>
      </div>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AlertsProvider>
        <Router>
          <AppLayout />
        </Router>
      </AlertsProvider>
    </QueryClientProvider>
  );
};

export default App;
