import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  getKeyValue,
} from '@nextui-org/react';
import React from 'react';
import { Member } from '../api/api';

interface TeamMemberSettingsProps {
  memberColumns: {
    key: string;
    label: string;
  }[];
  members: Member[];
  onInviteModalOpen: () => void;
  isInviteModalOpen: boolean;
  onInviteModalOpenChange: () => void;
  onInviteModalClose: () => void;
  invitedUser: string;
  setInvitedUser: React.Dispatch<React.SetStateAction<string>>;
  invitePending: boolean;
  handleUserInvite: () => Promise<void>;
}

const TeamMemberSettings: React.FC<TeamMemberSettingsProps> = ({
  memberColumns,
  members,
  onInviteModalOpen,
  isInviteModalOpen,
  onInviteModalOpenChange,
  onInviteModalClose,
  invitedUser,
  setInvitedUser,
  invitePending,
  handleUserInvite,
}) => {
  return (
    <div>
      <h3 className="text-lg text-setting-section-title font-medium">
        Team Members
      </h3>
      <Card className="p-4 gap-8 flex flex-col justify-center items-center">
        <Table aria-label="Example table with custom cells" removeWrapper>
          <TableHeader columns={memberColumns}>
            {(column) => (
              <TableColumn key={column.key}>{column.label}</TableColumn>
            )}
          </TableHeader>
          <TableBody items={members}>
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => (
                  <TableCell>{getKeyValue(item, columnKey)}</TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Button
          onPress={onInviteModalOpen}
          className="font-bold bg-button text-white hover:scale-105"
        >
          Invite New Member
        </Button>
      </Card>
      <Modal
        isOpen={isInviteModalOpen}
        onOpenChange={onInviteModalOpenChange}
        onClose={onInviteModalClose}
        backdrop="blur"
      >
        <ModalContent>
          {() => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-page-title">
                Invite New Team Member
              </ModalHeader>
              <ModalBody>
                <Input
                  isRequired
                  type="email"
                  label="Email"
                  defaultValue="max.mustermann@katalog.so"
                  className="w-full"
                  value={invitedUser}
                  onValueChange={setInvitedUser}
                />
              </ModalBody>
              <ModalFooter>
                {!invitePending ? (
                  <Button
                    className="w-full bg-button disabled:bg-disabled-button"
                    color="primary"
                    onPress={handleUserInvite}
                    disabled={invitedUser === ''}
                  >
                    Invite Member
                  </Button>
                ) : (
                  <Button
                    className="w-full bg-button"
                    color="primary"
                    isLoading
                  >
                    Inviting...
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default TeamMemberSettings;
