// src/api/auth.ts
import axios from 'axios';

const BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api/v1';

export interface User {
  id: string;
  name: string;
  email: string;
  profile_picture: string;
  company_id: string;
  role: string;
}

export const getUserInfo = async (): Promise<User> => {
  const token = localStorage.getItem('token');
  const response = await axios.get(`${BASE_URL}/auth/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });
  return response.data;
};

export const getUserInfoByToken = async (token: string): Promise<User> => {
  const response = await axios.get(`${BASE_URL}/auth/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });
  return response.data;
};

export const login = async (token: string): Promise<void> => {
  localStorage.setItem('token', token);
};

export const logout = (): void => {
  localStorage.removeItem('token');
};
