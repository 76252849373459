import React from 'react';
import { BarChart } from '@mui/x-charts';
import { Card, CardBody, CardHeader } from '@nextui-org/react';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

interface BarChartCardProps {
  name: string;
  datasetNames: string[];
  tableCounts: number[];
}

const BarChartCard: React.FC<BarChartCardProps> = ({
  name,
  datasetNames,
  tableCounts,
}) => {
  return (
    <Card className="w-full h-full">
      <CardHeader className="text-zinc-400 text-xl">{name}</CardHeader>
      <CardBody>
        <BarChart
          sx={() => ({
            width: '100%',
            height: '100%',
            [`.${axisClasses.root}`]: {
              [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                stroke: 'var(--chart-axis-color)',
                strokeWidth: 1,
              },
              [`.${axisClasses.tickLabel}`]: {
                fill: 'var(--chart-axis-color)',
                fontSize: 'xx-large',
              },
            },
          })}
          slotProps={{
            loadingOverlay: { message: 'Loading data...' },
            noDataOverlay: {
              message: 'No data to display',
              style: { fill: 'var(--chart-axis-color)' },
            },
          }}
          xAxis={[
            {
              id: 'barCategories',
              data: datasetNames,
              scaleType: 'band',
              tickLabelStyle: {
                angle: -25,
                textAnchor: 'end',
                fontSize: 12,
              },
            },
          ]}
          series={[
            {
              data: tableCounts,
              color: '#3b82f6',
            },
          ]}
          margin={{ top: 10, right: 10, bottom: 70, left: 70 }}
        />
      </CardBody>
    </Card>
  );
};

export default BarChartCard;
