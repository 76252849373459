import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  getKeyValue,
} from '@nextui-org/react';
import React from 'react';
import { Sync } from '../api/api';

interface MostRecentSyncsProps {
  syncColumns: {
    key: string;
    label: string;
  }[];
  syncs: Sync[];
  formatDate(dateString: string | undefined): string;
  syncPending: boolean;
  handleTriggerSync: () => Promise<void>;
}

const MostRecentSyncs: React.FC<MostRecentSyncsProps> = ({
  syncColumns,
  syncs,
  formatDate,
  syncPending,
  handleTriggerSync,
}) => {
  return (
    <div>
      <h3 className="text-lg text-setting-section-title font-medium">
        Last 5 Synchronizations
      </h3>
      <Card className="p-4 gap-8 flex flex-col justify-center items-center">
        <Table aria-label="Example table with custom cells" removeWrapper>
          <TableHeader columns={syncColumns}>
            {(column) => (
              <TableColumn key={column.key}>{column.label}</TableColumn>
            )}
          </TableHeader>
          <TableBody items={syncs}>
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) =>
                  columnKey === 'CreatedAt' ? (
                    <TableCell>{formatDate(item[columnKey])}</TableCell>
                  ) : (
                    <TableCell>{getKeyValue(item, columnKey)}</TableCell>
                  )
                }
              </TableRow>
            )}
          </TableBody>
        </Table>
        {!syncPending ? (
          <div>
            <Button
              className="font-bold bg-button text-white hover:scale-105"
              color="primary"
              onPress={handleTriggerSync}
            >
              Trigger New Sync
            </Button>
          </div>
        ) : (
          <div>
            <Button className="bg-button" color="primary" isLoading>
              Trigger New Sync
            </Button>
          </div>
        )}
      </Card>
    </div>
  );
};

export default MostRecentSyncs;
