import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
} from '@nextui-org/react';
import React from 'react';

interface DataStatProps {
  current: number;
  lastMonth: number;
  name: string;
}

const DataStat: React.FC<DataStatProps> = ({ current, lastMonth, name }) => {
  const ratio = lastMonth != 0 ? current / lastMonth : 1;
  const percentage = Math.round((ratio - 1) * 100) || 0;
  return (
    <Card className="w-full h-full">
      <CardHeader className="text-zinc-400 text-xl">{name}</CardHeader>
      <CardBody className="flex justify-center items-center">
        <div className="text-4xl sm:text-5xl font-bold">
          {current.toLocaleString()}
        </div>
      </CardBody>
      <CardFooter className="flex justify-center items-center pb-4">
        {ratio >= 1 || !ratio ? (
          <Chip className="bg-green-600/30 border border-green-600">
            <p>
              <span className="text-percentage-text-positive">
                ↑ (+{percentage}%)
              </span>{' '}
              compared to last month
            </p>
          </Chip>
        ) : (
          <Chip className="bg-red-600/30 border border-red-600">
            <p>
              <span className="text-percentage-text-negative">
                ↓ ({percentage}%)
              </span>{' '}
              compared to last month
            </p>
          </Chip>
        )}
      </CardFooter>
    </Card>
  );
};

export default DataStat;
