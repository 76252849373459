import React, { useEffect, useState } from 'react';
import { classNames, svgFillColors, svgPaths } from './severityStyles';

interface AlertProps {
  message?: string;
  severity?: 'info' | 'warning' | 'error' | 'success';
  timeout?: number;
  handleDismiss?: () => void;
}

const Alert: React.FC<AlertProps> = ({
  message = '',
  severity = 'info',
  timeout = 0,
  handleDismiss,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (message) {
      setShouldRender(true);
      setTimeout(() => setIsVisible(true), 50); // Delay to trigger fade-in
    }

    if (timeout > 0 && handleDismiss) {
      const timer = setTimeout(() => {
        handleDismissWithFadeOut();
      }, timeout * 1000);
      return () => clearTimeout(timer);
    }
  }, [message, timeout, handleDismiss]);

  const handleDismissWithFadeOut = () => {
    setIsVisible(false);
    setTimeout(() => {
      setShouldRender(false);
      if (handleDismiss) {
        handleDismiss();
      }
    }, 500); // Duration of the fade-out transition
  };

  if (!shouldRender) return null;

  return (
    <div
      className={`${classNames[severity]} rounded-lg px-4 py-3 mb-4 shadow-md pointer-events-auto transition-opacity duration-500 ease-in-out`}
      style={{ opacity: isVisible ? 1 : 0 }}
      role="alert"
    >
      <div className="flex">
        <div className="py-1">
          <svg
            className={`fill-current h-6 w-6 mr-4 ${svgFillColors[severity]}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d={svgPaths[severity]} />
          </svg>
        </div>
        <div>
          <p className="font-bold">{severity.toUpperCase()}</p>
          <p className="text-sm">{message}</p>
        </div>
        <div className="ml-auto">
          {handleDismiss && (
            <button
              className="text-sm font-bold"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                handleDismissWithFadeOut();
              }}
            >
              <svg
                className="fill-current h-6 w-6 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M6.83 5L10 8.17 13.17 5 15 6.83 11.83 10 15 13.17 13.17 15 10 11.83 6.83 15 5 13.17 8.17 10 5 6.83 6.83 5z" />
              </svg>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

interface AlertsWrapperProps {
  children: React.ReactNode;
}

const AlertsWrapper: React.FC<AlertsWrapperProps> = ({ children }) => {
  return (
    <div className="fixed inset-0 pointer-events-none z-[10000] flex items-end justify-center p-4">
      <div className="max-w-sm w-full">{children}</div>
    </div>
  );
};

export { Alert, AlertsWrapper };
