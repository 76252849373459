import React, {
  createContext,
  useContext,
  useRef,
  useState,
  ReactNode,
} from 'react';
import { Alert, AlertsWrapper } from './alert';

// Define the types for the alert
interface AlertType {
  id: string;
  message: string;
  severity: 'info' | 'warning' | 'error' | 'success';
  timeout?: number;
}

// Define the context type
interface AlertsContextType {
  alerts: AlertType[];
  addAlert: (alert: Omit<AlertType, 'id'>) => string;
  dismissAlert: (id: string) => void;
}

// Define the props for the provider component
interface AlertsProviderProps {
  children: ReactNode;
}

// Create the context with a default value of an empty object, and cast it to the context type
const AlertsContext = createContext<AlertsContextType | undefined>(undefined);

const AlertsProvider: React.FC<AlertsProviderProps> = ({ children }) => {
  const [alerts, setAlerts] = useState<AlertType[]>([]);

  const addAlert = (alert: Omit<AlertType, 'id'>): string => {
    const id =
      Math.random().toString(36).slice(2, 9) +
      new Date().getTime().toString(36);
    const newAlert = { ...alert, id: id };
    setAlerts((prev) => [newAlert, ...prev]);
    return id;
  };

  const dismissAlert = (id: string) => {
    setAlerts((prev) => prev.filter((alert) => alert.id !== id));
  };

  return (
    <AlertsContext.Provider value={{ alerts, addAlert, dismissAlert }}>
      <AlertsWrapper>
        {alerts.map((alert) => (
          <Alert
            key={alert.id}
            {...alert}
            handleDismiss={() => {
              dismissAlert(alert.id);
            }}
          />
        ))}
      </AlertsWrapper>
      {children}
    </AlertsContext.Provider>
  );
};

export const useAlerts = () => {
  const [alertIds, setAlertIds] = useState<string[]>([]);
  const alertIdsRef = useRef<string[]>(alertIds);
  const context = useContext(AlertsContext);

  if (!context) {
    throw new Error('useAlerts must be used within an AlertsProvider');
  }

  const { addAlert, dismissAlert } = context;

  const addAlertWithId = (alert: Omit<AlertType, 'id'>) => {
    const id = addAlert(alert);
    alertIdsRef.current.push(id);
    setAlertIds([...alertIdsRef.current]);
  };

  const clearAlerts = () => {
    alertIdsRef.current.forEach((id) => dismissAlert(id));
    alertIdsRef.current = [];
    setAlertIds([]);
  };

  return { addAlert: addAlertWithId, clearAlerts };
};

export default AlertsProvider;
