import React from 'react';

interface AccordionItemTitleProps {
  title: string;
  type: string;
}

const AccordionItemTitle: React.FC<AccordionItemTitleProps> = ({
  title,
  type,
}) => {
  return (
    <div className="flex flex-col sm:flex-row justify-between items-center">
      <div className="w-3/5 text-center sm:text-start whitespace-nowrap overflow-hidden text-ellipsis">
        {title}
      </div>
      <div className="flex flex-row gap-2 justify-center items-center">
        <div className="font-bold text-zinc-400">{type}</div>
      </div>
    </div>
  );
};

export default AccordionItemTitle;
