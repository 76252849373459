import React from 'react';
import { User } from '../api/auth';
import SidebarFooter from './SidebarFooter';
import { Select, SelectItem, SelectSection } from '@nextui-org/react';
import { Project, getProjects } from '../api/api';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import ThemeToggle from './ThemeToggle';
import HamburgerMenu from './HamburgerMenu';

interface SidebarProps {
  children: React.ReactNode;
  user: User;
  handleThemeSelect: (theme: string) => void;
  selected: string;
}

const Sidebar: React.FC<SidebarProps> = ({
  children,
  user,
  handleThemeSelect,
  selected,
}) => {
  const navigate = useNavigate();
  const { projectID } = useParams();
  const { data: projects } = useQuery<Project[]>({
    queryKey: ['projects'],
    queryFn: getProjects,
  });

  const handleSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'create-new-project') {
      navigate(`/projects`);
      return;
    }
    navigate(`/${e.target.value}/dashboard`);
  };

  return (
    <>
      <div
        id="separator-sidebar"
        className="z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0 hidden sm:flex"
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto bg-sidebar-background flex flex-col justify-between w-64">
          <ul className="space-y-2 font-medium">
            <div className="flex flex-col items-center justify-center border-b border-sidebar-divider py-4 gap-8">
              <img
                src={`${process.env.PUBLIC_URL}/katalog_logo.png`}
                className="w-1/3"
              />
              {projects && (
                <Select
                  size="sm"
                  variant="bordered"
                  label="Select a project"
                  className="max-w-xs"
                  onChange={handleSelectionChange}
                >
                  <SelectSection showDivider>
                    {projects.map((project) => (
                      <SelectItem key={project.id} className="text-zinc-500">
                        {project.name}
                      </SelectItem>
                    ))}
                  </SelectSection>
                  <SelectItem
                    key="create-new-project"
                    className="text-zinc-500"
                  >
                    + Create New Project
                  </SelectItem>
                </Select>
              )}
            </div>
            {children}
          </ul>
          <ul className="pt-4 mt-4 space-y-2 font-medium border-t border-sidebar-divider flex flex-col justify-center items-center">
            <ThemeToggle
              selected={selected}
              handleThemeSelect={handleThemeSelect}
            />
            <SidebarFooter
              text={user.name}
              link={`/${projectID}/profile`}
              iconSrc={user.profile_picture}
            />
          </ul>
        </div>
      </div>
      <HamburgerMenu projectID={projectID!} />
    </>
  );
};

export default Sidebar;
