import React from 'react';
import {
  mangoFusionPalette,
  pieArcLabelClasses,
  PieChart,
} from '@mui/x-charts';
import { Card, CardBody, CardHeader } from '@nextui-org/react';

interface columnTypeDistributionItem {
  id: number;
  value: number;
  label: string;
}

interface PieChartCardProps {
  name: string;
  columnTypeDistribution: columnTypeDistributionItem[];
}

const PieChartCard: React.FC<PieChartCardProps> = ({
  name,
  columnTypeDistribution,
}) => {
  return (
    <Card className="w-full h-full">
      <CardHeader className="text-zinc-400 text-xl">{name}</CardHeader>
      <CardBody className="overflow-hidden">
        <PieChart
          slotProps={{
            legend: { hidden: true },
            // legend: {
            //   direction: 'column',
            //   position: { vertical: 'top', horizontal: 'right' },
            //   itemMarkWidth: 10,
            //   itemMarkHeight: 10,
            //   markGap: 3,
            //   itemGap: 8,
            //   labelStyle: {
            //     fill: 'var(--chart-legend-color)',
            //     fontSize: 'small',
            //   },
            // },
            loadingOverlay: { message: 'Loading data...' },
            noDataOverlay: {
              message: 'No data to display',
              style: { fill: 'var(--chart-axis-color)' },
            },
          }}
          series={[
            {
              data: columnTypeDistribution,
              innerRadius: 40,
              outerRadius: '80%',
              paddingAngle: 4,
              cornerRadius: 4,
              arcLabel: (item) => `${item.value}`,
              arcLabelMinAngle: 30,
            },
          ]}
          colors={mangoFusionPalette}
          margin={{ top: -20, right: 0, bottom: 0, left: 0 }}
          sx={{
            width: '100%',
            height: '100%',
            [`& .${pieArcLabelClasses.root}`]: {
              fill: 'white',
              fontWeight: 'bold',
            },
          }}
        />
      </CardBody>
    </Card>
  );
};

export default PieChartCard;
