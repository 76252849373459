import { useDisclosure } from '@nextui-org/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState, useEffect, useCallback } from 'react';
import {
  getUserTeamMembers,
  Member,
  getHasKey,
  uploadKeyFile,
  HasKeyResponse,
  triggerSync,
  getSyncs,
  Sync,
  inviteUser,
} from '../api/api';
import { useAlerts } from '../alerts/alertContext';
import { useParams } from 'react-router-dom';
import ServiceAccountSettings from '../components/ServiceAccountSettings';
import MostRecentSyncs from '../components/MostRecentSyncs';
import TeamMemberSettings from '../components/TeamMemberSettings';
import Loading from '../components/Loading';
import CommandBar from '../components/CommandBar';

const Settings: React.FC = () => {
  const {
    isOpen: isUploadModalOpen,
    onOpen: onUploadModalOpen,
    onOpenChange: onUploadModalOpenChange,
    onClose: onUploadModalClose,
  } = useDisclosure();

  const {
    isOpen: isInviteModalOpen,
    onOpen: onInviteModalOpen,
    onOpenChange: onInviteModalOpenChange,
    onClose: onInviteModalClose,
  } = useDisclosure();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadPending, setUploadPending] = useState<boolean>(false);
  const [syncPending, setSyncPending] = useState<boolean>(false);
  const [invitePending, setInvitePending] = useState<boolean>(false);
  const [invitedUser, setInvitedUser] = useState<string>('');
  const [isCommandBarOpen, setIsCommandBarOpen] = useState(false);

  const queryClient = useQueryClient();
  const { addAlert } = useAlerts();
  const { projectID } = useParams();

  const {
    data: members,
    error: membersError,
    isLoading: membersLoading,
  } = useQuery<Member[]>({
    queryKey: ['members'],
    queryFn: getUserTeamMembers,
  });

  const {
    data: hasKey,
    error: hasKeyError,
    isLoading: hasKeyLoading,
  } = useQuery<HasKeyResponse>({
    queryKey: ['hasKey', projectID],
    queryFn: () => getHasKey(projectID!),
  });

  const {
    data: syncs,
    error: syncsError,
    isLoading: syncsLoading,
  } = useQuery<Sync[]>({
    queryKey: ['syncs', projectID],
    queryFn: () => getSyncs(projectID!),
  });

  useEffect(() => {
    if (membersError) {
      addAlert({
        severity: 'error',
        message: membersError.message,
        timeout: 5,
      });
    }
  }, [membersError, addAlert]);

  useEffect(() => {
    if (hasKeyError) {
      addAlert({
        severity: 'error',
        message: hasKeyError.message,
        timeout: 5,
      });
    }
  }, [hasKeyError, addAlert]);

  useEffect(() => {
    if (syncsError) {
      addAlert({
        severity: 'error',
        message: syncsError.message,
        timeout: 5,
      });
    }
  }, [syncsError, addAlert]);

  const handleFileSelect = (file: File) => {
    setSelectedFile(file);
  };

  const handleFileUpload = async () => {
    setUploadPending(true);
    if (!selectedFile) {
      addAlert({
        severity: 'error',
        message: 'No file selected',
        timeout: 5,
      });
      setUploadPending(false);
      return;
    }

    try {
      await uploadKeyFile(selectedFile, projectID!);
      addAlert({
        severity: 'success',
        message: 'File successfully uploaded!',
        timeout: 3,
      });
      queryClient.invalidateQueries({ queryKey: ['hasKey'] });
      setUploadPending(false);
      handleModalClose();
    } catch (error) {
      console.error('Error uploading file:', error);
      addAlert({
        severity: 'error',
        message: 'Failed to upload file',
        timeout: 5,
      });
      setUploadPending(false);
      setSelectedFile(null);
    }
  };

  const handleUserInvite = async () => {
    setInvitePending(true);
    if (invitedUser === '') {
      addAlert({
        severity: 'error',
        message: 'User email is missing',
        timeout: 5,
      });
      setInvitePending(false);
      return;
    }

    try {
      await inviteUser(invitedUser);
      addAlert({
        severity: 'success',
        message: `Successfully invited: ${invitedUser}`,
        timeout: 5,
      });

      setInvitePending(false);
      onInviteModalClose();
    } catch (error) {
      console.error('Error inviting user:', error);
      addAlert({
        severity: 'error',
        message: 'Failed to invite user',
        timeout: 5,
      });
      setInvitePending(false);
      onInviteModalClose();
    }
  };

  const handleTriggerSync = async () => {
    setSyncPending(true);
    try {
      await triggerSync(projectID!);
      addAlert({
        severity: 'success',
        message: 'Schema synced successfully!',
        timeout: 3,
      });
      queryClient.invalidateQueries({ queryKey: ['syncs'] });
      setSyncPending(false);
    } catch (error) {
      console.error('Error triggering schema sync:', error);
      addAlert({
        severity: 'error',
        message: 'Failed to trigger sync for schema',
        timeout: 5,
      });
      setSyncPending(false);
    }
  };

  const handleModalClose = () => {
    setSelectedFile(null);
    onUploadModalClose();
  };

  const memberColumns = [
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'role',
      label: 'Role',
    },
  ];

  const syncColumns = [
    {
      key: 'id',
      label: 'ID',
    },
    {
      key: 'CreatedAt',
      label: 'Timestamp',
    },
  ];

  const formatDate = (dateString: string) => {
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
      }
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    } catch (error) {
      console.error('Invalid date value:', error);
      return 'Invalid date';
    }
  };

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
      event.preventDefault();
      setIsCommandBarOpen(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return (
    <div className="p-5 w-full h-full overflow-auto">
      <h1 className="text-2xl mb-4 font-medium text-page-title">Settings</h1>
      {(membersLoading || hasKeyLoading || syncsLoading) && <Loading />}
      {members && syncs && (
        <div className="flex flex-col gap-8 w-full">
          <ServiceAccountSettings
            hasKey={hasKey?.hasKey}
            formatDate={formatDate}
            keyCreatedAt={hasKey?.createdAt}
            onUploadModalOpen={onUploadModalOpen}
            isUploadModalOpen={isUploadModalOpen}
            onUploadModalOpenChange={onUploadModalOpenChange}
            handleModalClose={handleModalClose}
            handleFileSelect={handleFileSelect}
            handleFileUpload={handleFileUpload}
            uploadPending={uploadPending}
            selectedFile={selectedFile}
          />
          <MostRecentSyncs
            syncColumns={syncColumns}
            syncs={syncs}
            formatDate={formatDate}
            syncPending={syncPending}
            handleTriggerSync={handleTriggerSync}
          />
          <TeamMemberSettings
            memberColumns={memberColumns}
            members={members}
            onInviteModalOpen={onInviteModalOpen}
            isInviteModalOpen={isInviteModalOpen}
            onInviteModalOpenChange={onInviteModalOpenChange}
            onInviteModalClose={onInviteModalClose}
            invitedUser={invitedUser}
            setInvitedUser={setInvitedUser}
            invitePending={invitePending}
            handleUserInvite={handleUserInvite}
          />
        </div>
      )}
      <CommandBar
        isOpen={isCommandBarOpen}
        onClose={() => setIsCommandBarOpen(false)}
        projectID={projectID!}
      />
    </div>
  );
};

export default Settings;
