import React from 'react';
import { Spinner } from '@nextui-org/react';

const Loading = () => {
  return (
    <div className="flex items-center justify-center h-full w-auto overflow-none">
      <Spinner color="primary" />
    </div>
  );
};

export default Loading;
