import React, { useEffect, useState, useCallback } from 'react';
import { BreadcrumbItem, Breadcrumbs, Card } from '@nextui-org/react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getChangelogs, Changelog } from '../api/api';
import Loading from '../components/Loading';
import CommandBar from '../components/CommandBar';

const Releases: React.FC = () => {
  const { projectID, syncID } = useParams();
  const [formattedDatasetChangelogs, setFormattedDatasetChangelogs] = useState<
    string[]
  >([]);
  const [formattedTableChangelogs, setFormattedTableChangelogs] = useState<
    string[]
  >([]);
  const [formattedColumnChangelogs, setFormattedColumnChangelogs] = useState<
    string[]
  >([]);
  const [isCommandBarOpen, setIsCommandBarOpen] = useState(false);
  const {
    data: changelogs,
    error,
    isLoading,
  } = useQuery<Changelog[]>({
    queryKey: ['changelogs', projectID, syncID],
    queryFn: () => getChangelogs(projectID!, syncID!),
    enabled: !!projectID,
  });

  function strikethrough(text: string): string {
    return text
      .split('')
      .map((char) => char + '\u0336')
      .join('');
  }

  const formatChangelogs = (changelogs: Changelog[]) => {
    const formattedDatasetChangelogs: string[] = [];
    const formattedTableChangelogs: string[] = [];
    const formattedColumnChangelogs: string[] = [];
    for (let i = 0; i < changelogs.length; i++) {
      const changelog = changelogs[i];
      switch (`${changelog.entity_type}-${changelog.change_type}`) {
        case 'dataset-insert':
          formattedDatasetChangelogs.push(
            `Dataset <strong><span style="color: green;">${changelog.entity_name}</span></strong> was created`
          );
          break;
        case 'dataset-update':
          formattedDatasetChangelogs.push(
            `Dataset <strong>${changelog.entity_name}'s</strong> property <strong>${changelog.field_name}</strong>  was changed: <strong><span style="color: red;">${strikethrough(changelog.old_value)}</span> → <span style="color: green;">${changelog.new_value}</span></strong>`
          );
          break;
        case 'dataset-delete':
          formattedDatasetChangelogs.push(
            `Dataset <strong><span style="color: red;">${changelog.entity_name}</span></strong> was deleted`
          );
          break;
        case 'table-insert':
          formattedTableChangelogs.push(
            `Table <strong>${changelog.parent_name}.<span style="color: green;">${changelog.entity_name}</span></strong> was created`
          );
          break;
        case 'table-update':
          formattedTableChangelogs.push(
            `Table <strong>${changelog.parent_name}.${changelog.entity_name}'s</strong> property <strong>${changelog.field_name}</strong> was changed: <strong><span style="color: red;">${strikethrough(changelog.old_value)}</span> → <span style="color: green;">${changelog.new_value}</span></strong>`
          );
          break;
        case 'table-delete':
          formattedTableChangelogs.push(
            `Table <strong>${changelog.parent_name}.<span style="color: red;">${changelog.entity_name}</span></strong> was deleted`
          );
          break;
        case 'column-insert':
          formattedColumnChangelogs.push(
            `Column <strong><span style="color: green;">${changelog.entity_name}</span> on ${changelog.grandparent_name}.${changelog.parent_name}</strong> was created`
          );
          break;
        case 'column-update':
          formattedColumnChangelogs.push(
            `Column <strong>${changelog.entity_name}'s</strong> (${changelog.grandparent_name}.${changelog.parent_name}) property <strong>${changelog.field_name}</strong> was changed: <strong><span style="color: red;">${strikethrough(changelog.old_value)}</span> → <span style="color: green;">${changelog.new_value}</span></strong>`
          );
          break;
        case 'column-delete':
          formattedColumnChangelogs.push(
            `Column <strong><span style="color: red;">${changelog.entity_name}</span> on ${changelog.grandparent_name}.${changelog.parent_name}</strong> was deleted`
          );
      }
    }
    return {
      dataset: formattedDatasetChangelogs,
      table: formattedTableChangelogs,
      column: formattedColumnChangelogs,
    };
  };

  useEffect(() => {
    if (changelogs) {
      const tempChangelogs = formatChangelogs(changelogs);
      setFormattedDatasetChangelogs(tempChangelogs.dataset);
      setFormattedTableChangelogs(tempChangelogs.table);
      setFormattedColumnChangelogs(tempChangelogs.column);
    }
  }, [changelogs]);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
      event.preventDefault();
      setIsCommandBarOpen(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return (
    <div className="p-5 w-full h-full overflow-auto">
      <h1 className="text-2xl font-medium text-page-title">Changelog</h1>
      <Breadcrumbs size="lg" className="mb-4">
        <BreadcrumbItem href={`/${projectID}/releases`} className="opacity-50">
          Releases
        </BreadcrumbItem>
        <BreadcrumbItem href={`/${projectID}/releases`} className="opacity-50">
          <span className="max-w-[100px] whitespace-nowrap overflow-hidden text-ellipsis">
            Release {syncID}
          </span>
        </BreadcrumbItem>
      </Breadcrumbs>
      {error instanceof Error && (
        <p className="text-red-500">{error.message}</p>
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <h3 className="text-lg text-setting-section-title font-medium">
            Dataset Level Changes:
          </h3>
          <Card className="flex flex-col gap-2 bg-accordion-background px-16 py-8 shadow-none mb-8">
            {formattedDatasetChangelogs.length > 0 ? (
              <ol
                style={{ listStyleType: 'decimal' }}
                className="flex flex-col gap-2"
              >
                {formattedDatasetChangelogs.map(
                  (changelog: string, index: number) => (
                    <li
                      key={index}
                      dangerouslySetInnerHTML={{ __html: changelog }}
                    />
                  )
                )}
              </ol>
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                <p className="text-zinc-400">No changes detected</p>
              </div>
            )}
          </Card>
          <h3 className="text-lg text-setting-section-title font-medium">
            Table Level Changes:
          </h3>
          <Card className="flex flex-col gap-2 bg-accordion-background px-16 py-8 shadow-none mb-8">
            {formattedTableChangelogs.length > 0 ? (
              <ol
                style={{ listStyleType: 'decimal' }}
                className="flex flex-col gap-2"
              >
                {formattedTableChangelogs.map(
                  (changelog: string, index: number) => (
                    <li
                      key={index}
                      dangerouslySetInnerHTML={{ __html: changelog }}
                    />
                  )
                )}
              </ol>
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                <p className="text-zinc-400">No changes detected</p>
              </div>
            )}
          </Card>
          <h3 className="text-lg text-setting-section-title font-medium">
            Column Level Changes
          </h3>
          <Card className="flex flex-col gap-2 bg-accordion-background px-16 py-8 shadow-none mb-8">
            {formattedColumnChangelogs.length > 0 ? (
              <ol
                style={{ listStyleType: 'decimal' }}
                className="flex flex-col gap-2"
              >
                {formattedColumnChangelogs.map(
                  (changelog: string, index: number) => (
                    <li
                      key={index}
                      dangerouslySetInnerHTML={{ __html: changelog }}
                    />
                  )
                )}
              </ol>
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                <p className="text-zinc-400">No changes detected</p>
              </div>
            )}
          </Card>
        </>
      )}
      <CommandBar
        isOpen={isCommandBarOpen}
        onClose={() => setIsCommandBarOpen(false)}
        projectID={projectID!}
      />
    </div>
  );
};

export default Releases;
