import React from 'react';

interface AccordionItemTitleProps {
  title: string;
  rowCount: number;
  columnCount: number;
  rowIconSrc: string;
  columnIconSrc: string;
}

const AccordionItemTitle: React.FC<AccordionItemTitleProps> = ({
  title,
  rowCount,
  columnCount,
  rowIconSrc,
  columnIconSrc,
}) => {
  return (
    <div className="flex flex-col sm:flex-row justify-between items-center">
      <div className="w-3/5 text-center sm:text-start whitespace-nowrap overflow-hidden text-ellipsis">
        {title}
      </div>
      <div className="flex flex-row gap-3 justify-center items-center">
        <div className="flex flex-row">
          <div className="font-bold text-zinc-400">{columnCount} x </div>
          <img className="w-7 h-7" src={columnIconSrc} alt="Table Icon" />
        </div>
        <div className="flex flex-row">
          <div className="font-bold mr-1 text-zinc-400">{rowCount} x </div>
          <img className="w-7 h-7" src={rowIconSrc} alt="Table Icon" />
        </div>
      </div>
    </div>
  );
};

export default AccordionItemTitle;
