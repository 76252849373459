import { Card, CardBody, CardFooter, CardHeader } from '@nextui-org/react';
import React from 'react';

interface ChangeStatProps {
  name: string;
  insertions: number;
  deletions: number;
  updates: number;
}

const ChangeStat: React.FC<ChangeStatProps> = ({
  name,
  insertions,
  deletions,
  updates,
}) => {
  return (
    <Card className="w-full h-full">
      <CardHeader className="text-zinc-400 text-xl">{name}</CardHeader>
      <CardBody className="flex flex-row justify-between items-center px-12 sm:px-24">
        <div className="flex flex-col justify-center items-center">
          <div className="text-4xl sm:text-5xl text-green-500/80 font-bold">
            +{insertions.toLocaleString()}
          </div>
          <div className="text-green-500/60">additions</div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="text-4xl sm:text-5xl text-red-500/80 font-bold">
            -{deletions.toLocaleString()}
          </div>
          <div className="text-red-500/60">deletions</div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="text-4xl sm:text-5xl text-yellow-500/80 font-bold">
            {updates.toLocaleString()}
          </div>
          <div className="text-yellow-500/60">updates</div>
        </div>
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  );
};

export default ChangeStat;
