import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserInfoByToken, login } from '../api/auth';
import { Button, Input } from '@nextui-org/react';
import { useQueryClient } from '@tanstack/react-query';
import { signUpUser } from '../api/api';
import { useAlerts } from '../alerts/alertContext';

const CreateCompany: React.FC = () => {
  const [companyName, setCompanyName] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { addAlert } = useAlerts();

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    if (!token) {
      navigate('/dashboard');
    } else {
      login(token);
      getUserInfoByToken(token).then((user) => {
        if (user?.company_id) {
          navigate('/dashboard');
        }
      });
    }
  }, [location, navigate]);

  const handleCompanyCreation = async () => {
    setRequestLoading(true);
    try {
      const response = await signUpUser(companyName, projectName);
      queryClient.invalidateQueries({ queryKey: ['user'] });
      setRequestLoading(false);
      navigate(`/${response.project_id}/dashboard`);
    } catch (error) {
      setRequestLoading(false);
      addAlert({
        severity: 'error',
        message: 'Failed to sign user up',
        timeout: 5,
      });
      throw new Error('Failed to sign user up');
    }
  };

  return (
    <div className="min-w-screen h-screen flex flex-col justify-center items-center gap-4">
      <Input
        isRequired
        type="text"
        label="Company Name"
        defaultValue="Acme Corp."
        classNames={{
          base: 'max-w-xs',
          inputWrapper: [
            'bg-input-background',
            'group-data-[focus=true]:bg-input-background',
            'hover:bg-input-background',
          ],
        }}
        value={companyName}
        onValueChange={setCompanyName}
      />
      <Input
        isRequired
        type="text"
        label="Project Name"
        defaultValue="Acme Project"
        classNames={{
          base: 'max-w-xs',
          inputWrapper: [
            'bg-input-background',
            'group-data-[focus=true]:bg-input-background',
            'hover:bg-input-background',
          ],
        }}
        value={projectName}
        onValueChange={setProjectName}
      />
      {requestLoading ? (
        <Button
          onPress={handleCompanyCreation}
          className="bg-blue-600 text-white font-semibold"
          isLoading
        >
          Create Company & Project
        </Button>
      ) : (
        <Button
          onPress={handleCompanyCreation}
          className="bg-blue-600 text-white font-semibold"
        >
          Create Company & Project
        </Button>
      )}
    </div>
  );
};

export default CreateCompany;
