import React, { useCallback, useEffect, useState } from 'react';
import { getDatasets, Dataset } from '../api/api';
import {
  Accordion,
  AccordionItem,
  BreadcrumbItem,
  Breadcrumbs,
  Button,
  Kbd,
} from '@nextui-org/react';
import AccordionItemTitleDataset from '../components/AccordionItemTitleDataset';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import CommandBar from '../components/CommandBar';

const Catalog: React.FC = () => {
  const { projectID } = useParams();
  const [isCommandBarOpen, setIsCommandBarOpen] = useState(false);

  const { data: datasets, error } = useQuery<Dataset[]>({
    queryKey: ['datasets', projectID],
    queryFn: () => getDatasets(projectID!),
    enabled: !!projectID,
  });

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
      event.preventDefault();
      setIsCommandBarOpen(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return (
    <div className="p-5 w-full h-full overflow-auto">
      <div className="w-full flex flex-row justify-between">
        <h1 className="text-2xl font-medium text-page-title">Datasets</h1>
        <Button
          size="md"
          variant="bordered"
          startContent={
            <img
              src={`${process.env.PUBLIC_URL}/search_icon_new.svg`}
              className="h-4 w-4 opacity-50"
            />
          }
          onPress={() => setIsCommandBarOpen(true)}
        >
          <span className="opacity-50">
            Search <Kbd keys={['command']}>K</Kbd>
          </span>
        </Button>
      </div>
      <Breadcrumbs size="lg" className="mb-4">
        <BreadcrumbItem href={`/${projectID}/catalog`} className="opacity-50">
          Datasets
        </BreadcrumbItem>
      </Breadcrumbs>
      {error instanceof Error && (
        <p className="text-red-500">{error.message}</p>
      )}
      {datasets == null ? (
        <div className="h-64 w-full flex justify-center items-center text-center text-service-account-text align-middle">
          <span>
            There are no datasets yet. Set up your integration on the settings
            page. If you&apos;ve already done that, create some datasets on
            BigQuery and trigger a sync.
          </span>
        </div>
      ) : (
        <Accordion
          className="rounded-xl px-0 bg-accordion-background"
          selectionMode="multiple"
        >
          {(datasets ?? []).map((dataset: Dataset) => (
            <AccordionItem
              key={dataset.id}
              title={
                <AccordionItemTitleDataset
                  title={dataset.name}
                  count={dataset.table_count}
                  iconSrc="/table_icon.svg"
                />
              }
              aria-label={dataset.name}
              className="rounded-md px-4 hover:bg-accordion-item-hover"
            >
              <div className="pb-4 text-sm font-light text-accordion-text">
                {dataset.description}
                <br />
                <a
                  href={`/${projectID}/datasets/${dataset.id}?datasetName=${dataset.name}`}
                  className="inline-flex items-center hover:underline mt-2"
                >
                  <svg
                    className="w-4 h-4 mr-2 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                  View dataset content
                </a>
              </div>
            </AccordionItem>
          ))}
        </Accordion>
      )}
      <CommandBar
        isOpen={isCommandBarOpen}
        onClose={() => setIsCommandBarOpen(false)}
        projectID={projectID!}
      />
    </div>
  );
};

export default Catalog;
