import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createProject, getProjects, Project } from '../api/api';
import {
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react';
import { useQuery } from '@tanstack/react-query';
import { useAlerts } from '../alerts/alertContext';

const CreateCompany: React.FC = () => {
  const navigate = useNavigate();
  const { addAlert } = useAlerts();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [projectName, setProjectName] = useState<string>('');
  const [projectPending, setProjectPending] = useState<boolean>(false);
  const { data: projects } = useQuery<Project[]>({
    queryKey: ['projects'],
    queryFn: getProjects,
  });

  const handleProjectCreation = async () => {
    setProjectPending(true);
    if (projectName === '') {
      addAlert({
        severity: 'error',
        message: 'Project name is missing',
        timeout: 5,
      });
      setProjectPending(false);
      return;
    }

    try {
      const projectID = await createProject(projectName);
      setProjectPending(false);
      navigate(`/${projectID}/dashboard`);
    } catch (error) {
      console.error('Error inviting user:', error);
      addAlert({
        severity: 'error',
        message: 'Failed to invite user',
        timeout: 5,
      });
      setProjectPending(false);
      onClose();
    }
  };

  return (
    <div className="w-screen h-screen flex flex-col px-8 py-8 lg:px-96 lg:py-24 h-full overflow-auto">
      <h1 className="text-2xl font-medium mb-8 text-page-title">
        Your Projects
      </h1>
      <div className="flex flex-row flex-wrap items-center gap-12">
        {projects &&
          projects.map((project) => {
            return (
              <Card
                shadow="sm"
                key={project.id}
                isPressable
                onPress={() => {
                  navigate(`/${project.id}/dashboard`);
                }}
                className="p-4 w-48 h-64 flex justify-center items-center hover:scale-105 hover:shadow-xl hover:border-2 hover:border-project-card-border transition-all ease-in"
              >
                <CardBody className="font-medium flex justify-center items-center text-center">
                  {project.name}
                </CardBody>
              </Card>
            );
          })}
        <Card
          shadow="sm"
          key="1234"
          isPressable
          onPress={onOpen}
          className="p-4 w-48 h-64 bg-new-project-card-background flex justify-center items-center hover:scale-105 hover:shadow-xl hover:border-2 hover:border-project-card-border transition-all ease-in"
        >
          <CardBody className="font-bold flex justify-center items-center text-center text-zinc-400">
            +<br />
            Create New Project
          </CardBody>
        </Card>
        <Modal isOpen={isOpen} onClose={onClose} backdrop="blur">
          <ModalContent>
            {() => (
              <>
                <ModalHeader className="flex flex-col gap-1 text-page-title">
                  Create New Project
                </ModalHeader>
                <ModalBody>
                  <Input
                    isRequired
                    type="text"
                    label="Project Name"
                    defaultValue="GPT-7"
                    className="w-full"
                    value={projectName}
                    onValueChange={setProjectName}
                  />
                </ModalBody>
                <ModalFooter>
                  {!projectPending ? (
                    <Button
                      className="w-full bg-button disabled:bg-disabled-button"
                      color="primary"
                      onPress={handleProjectCreation}
                      disabled={projectName === ''}
                    >
                      Create Project
                    </Button>
                  ) : (
                    <Button
                      className="w-full bg-button"
                      color="primary"
                      isLoading
                    >
                      Creating...
                    </Button>
                  )}
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
};

export default CreateCompany;
