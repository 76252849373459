import React, { useState, useCallback, useEffect } from 'react';
import { getTables, Table } from '../api/api';
import {
  Accordion,
  AccordionItem,
  BreadcrumbItem,
  Breadcrumbs,
} from '@nextui-org/react';
import AccordionItemTitleTable from '../components/AccordionItemTitleTable';
import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import CommandBar from '../components/CommandBar';

const Tables: React.FC = () => {
  const { projectID, datasetId } = useParams<{
    projectID: string;
    datasetId: string;
  }>();

  const [searchParams] = useSearchParams();
  const datasetName = searchParams.get('datasetName');
  const [isCommandBarOpen, setIsCommandBarOpen] = useState(false);

  const { data: tables, error } = useQuery<Table[], Error>({
    queryKey: ['tables', datasetId],
    queryFn: () => getTables(datasetId!),
  });

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
      event.preventDefault();
      setIsCommandBarOpen(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return (
    <div className="p-5 w-full h-full overflow-auto">
      <h1 className="text-2xl font-medium text-page-title">Tables</h1>
      {error instanceof Error && (
        <p className="text-red-500">{error.message}</p>
      )}
      <Breadcrumbs size="lg" className="mb-4" underline="hover">
        <BreadcrumbItem href={`/${projectID}/catalog`}>Datasets</BreadcrumbItem>
        <BreadcrumbItem href={`/${projectID}/catalog`} className="opacity-50">
          {datasetName}
        </BreadcrumbItem>
      </Breadcrumbs>
      <Accordion
        className="rounded-xl px-0 bg-accordion-background"
        selectionMode="multiple"
      >
        {(tables ?? []).map((table: Table) => (
          <AccordionItem
            key={table.id}
            title={
              <AccordionItemTitleTable
                title={table.name}
                rowCount={table.row_count}
                columnCount={table.column_count}
                rowIconSrc={`${process.env.PUBLIC_URL}/row_icon.svg`}
                columnIconSrc={`${process.env.PUBLIC_URL}/column_icon.svg`}
              />
            }
            aria-label={table.name}
            className="rounded-md px-4 hover:bg-accordion-item-hover"
          >
            <div className="pb-4 text-sm font-light text-accordion-text">
              {table.description}
              <br />
              <a
                href={`/${projectID}/tables/${table.id}?datasetID=${datasetId}&datasetName=${datasetName}&tableName=${table.name}`}
                className="inline-flex items-center hover:underline mt-2"
              >
                <svg
                  className="w-4 h-4 mr-2 rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
                View table content
              </a>
            </div>
          </AccordionItem>
        ))}
      </Accordion>
      <CommandBar
        isOpen={isCommandBarOpen}
        onClose={() => setIsCommandBarOpen(false)}
        projectID={projectID!}
      />
    </div>
  );
};

export default Tables;
