import React from 'react';

interface AccordionItemTitleProps {
  title: string;
  count: number;
  iconSrc: string;
}

const AccordionItemTitle: React.FC<AccordionItemTitleProps> = ({
  title,
  count,
  iconSrc,
}) => {
  return (
    <div className="flex flex-col sm:flex-row justify-between items-center">
      <div className="w-3/5 text-center sm:text-start whitespace-nowrap overflow-hidden text-ellipsis">
        {title}
      </div>
      <div className="flex flex-row gap-2 justify-center items-center">
        <div className="font-bold text-zinc-400">{count} x </div>
        <img className="w-5 h-5" src={iconSrc} alt="Table Icon" />
      </div>
    </div>
  );
};

export default AccordionItemTitle;
