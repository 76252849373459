// components/CommandBar.tsx
import React, { useState, useEffect, useRef } from 'react';
import {
  Input,
  Modal,
  ModalContent,
  ModalBody,
  Spinner,
} from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import { searchResources, SearchResult } from '../api/api';
import { useQuery } from '@tanstack/react-query';

interface CommandBarProps {
  isOpen: boolean;
  onClose: () => void;
  projectID: string;
}

const CommandBar: React.FC<CommandBarProps> = ({
  isOpen,
  onClose,
  projectID,
}) => {
  const [query, setQuery] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();
  const resultsRef = useRef<HTMLUListElement>(null);

  const {
    data: results,
    isLoading,
    error,
  } = useQuery<SearchResult[]>({
    queryKey: ['search', projectID, query],
    queryFn: () => searchResources(projectID, query),
    enabled: !!query && query.length > 2,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  useEffect(() => {
    setSelectedIndex(0);
  }, [results]);

  // Reset query when modal is closed
  useEffect(() => {
    if (!isOpen) {
      setQuery('');
    }
  }, [isOpen]);

  const handleResultClick = (result: SearchResult) => {
    switch (result.type) {
      case 'dataset':
        navigate(
          `/${projectID}/datasets/${result.id}?datasetName=${result.name}`
        );
        break;
      case 'table':
        navigate(
          `/${projectID}/tables/${result.id}?datasetName=${result.dataset_name}&tableName=${result.name}`
        );
        break;
      case 'column':
        navigate(
          `/${projectID}/tables/${result.table_id}?datasetName=${result.dataset_name}&tableName=${result.table_name}`
        );
        break;
    }
    handleClose();
  };

  const handleClose = () => {
    setQuery('');
    onClose();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      handleClose();
      return;
    }

    if (results) {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setSelectedIndex((prevIndex) =>
            prevIndex < results.length - 1 ? prevIndex + 1 : prevIndex
          );
          break;
        case 'ArrowUp':
          e.preventDefault();
          setSelectedIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : prevIndex
          );
          break;
        case 'Enter':
          e.preventDefault();
          if (results[selectedIndex]) {
            handleResultClick(results[selectedIndex]);
          }
          break;
      }
    }
  };

  useEffect(() => {
    if (resultsRef.current) {
      const selectedElement = resultsRef.current.children[
        selectedIndex
      ] as HTMLElement;
      if (selectedElement) {
        selectedElement.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [selectedIndex]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      hideCloseButton
      className="bg-transparent border-none shadow-none"
    >
      <ModalContent className="m-0 p-0">
        <ModalBody className="p-0">
          <div className="fixed top-1/4 left-1/2 transform -translate-x-1/2 w-full max-w-2xl">
            <Input
              autoFocus
              placeholder="Search datasets, tables, or columns..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={handleKeyDown}
              className="w-full"
            />
            <div className="mt-4 bg-search-results-background rounded-md shadow-lg max-h-[60vh] overflow-y-auto">
              {isLoading && <Spinner className="m-4" />}
              {error && (
                <p className="text-red-500 m-4">
                  An error occurred while searching
                </p>
              )}
              {results && results.length > 0 && (
                <ul ref={resultsRef} className="divide-y divide-gray-200">
                  {results.map((result, index) => (
                    <li
                      key={result.id}
                      onClick={() => handleResultClick(result)}
                      className={`cursor-pointer hover:bg-search-results-highlighted p-4 ${
                        index === selectedIndex
                          ? 'bg-search-results-highlighted'
                          : ''
                      }`}
                    >
                      <div className="font-medium text-search-results-title">
                        {result.name}
                      </div>
                      <div className="text-sm text-zinc-400">{result.type}</div>
                      <div className="text-sm text-zinc-400">
                        {result.description}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CommandBar;
