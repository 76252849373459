import axios from 'axios';

const BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api/v1';

export interface Dataset {
  id: string;
  name: string;
  project_id: string;
  description: string;
  table_count: number;
}

export interface Table {
  id: string;
  name: string;
  dataset_id: string;
  description: string;
  column_count: number;
  row_count: number;
}

export interface Column {
  id: string;
  name: string;
  type: string;
  table_id: string;
  description: string;
}

export interface Member {
  id: string;
  name: string;
  email: string;
  role: string;
}

export interface HasKeyResponse {
  hasKey: boolean;
  createdAt: string;
}

export interface Sync {
  id: string;
  CreatedAt: string;
}

export interface Changelog {
  id: string;
  change_type: string;
  entity_type: string;
  entity_id: string;
  entity_name: string;
  field_name: string;
  old_value: string;
  new_value: string;
  parent_id: string;
  parent_name: string;
  grandparent_id: string;
  grandparent_name: string;
  sync_id: string;
}

export interface SyncWithChangelogs {
  id: string;
  CreatedAt: string;
  changelogs: Changelog[];
}

export interface Project {
  id: string;
  name: string;
  company_id: string;
}

export interface UserWithCompany {
  id: string;
  name: string;
  email: string;
  profile_picture: string;
  company_id: string;
  role: string;
  status: string;
  company: string;
}

export interface DashboardAnalyticsResponse {
  userHasSync: boolean;
  totalDatasetCount: number;
  totalTableCount: number;
  totalColumnCount: number;
  totalRowCount: number;
  pastMonthDatasetCount: number;
  pastMonthTableCount: number;
  pastMonthColumnCount: number;
  pastMonthTotalRowCount: number;
  tableCounts: {
    datasetNames: string[];
    tableCounts: number[];
  };
  tableSizeMetric: {
    datasetNames: string[];
    avgRowCounts: number[];
    tableCounts: number[];
  };
  columnTypeDistribution: {
    id: number;
    label: string;
    value: number;
  }[];
  currentMonthSyncCount: number;
  pastMonthSyncCount: number;
  currentMonthChangeCounts: {
    insert: number;
    update: number;
    delete: number;
  };
}

export interface SearchResult {
  id: string;
  type: 'dataset' | 'table' | 'column';
  name: string;
  description: string;
  table_name: string;
  dataset_name: string;
  table_id: string;
}

export const loginUser = async (username: string, password: string) => {
  try {
    const response = await axios.post<{ token: string }>(
      `${BASE_URL}/auth/login`,
      {
        username,
        password,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to log in');
  }
};

export const getDatasets = async (projectID: string): Promise<Dataset[]> => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(`${BASE_URL}/datasets/${projectID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response.data.datasets;
  } catch (error) {
    throw new Error('Failed to fetch datasets');
  }
};

export const getTables = async (datasetId: string): Promise<Table[]> => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(`${BASE_URL}/tables/${datasetId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response.data.tables;
  } catch (error) {
    throw new Error('Failed to fetch tables');
  }
};

export const getColumns = async (tableId: string): Promise<Column[]> => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(`${BASE_URL}/columns/${tableId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response.data.columns;
  } catch (error) {
    throw new Error('Failed to fetch columns');
  }
};

export const getUserTeamMembers = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(`${BASE_URL}/companies/members`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    return response.data.members;
  } catch (error) {
    throw new Error("Failed to fetch user's team members");
  }
};

export const getHasKey = async (projectID: string) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(
      `${BASE_URL}/projects/${projectID}/hasKey`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to get whether project has key');
  }
};

export const uploadKeyFile = async (file: File, projectID: string) => {
  const token = localStorage.getItem('token');
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.post(
      `${BASE_URL}/files/${projectID}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to upload file');
  }
};

export const triggerSync = async (projectID: string) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.post(
      `${BASE_URL}/schema/${projectID}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to trigger schema sync');
  }
};

export const getSyncs = async (projectID: string) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(`${BASE_URL}/schema/${projectID}/syncs`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    return response.data.syncs;
  } catch (error) {
    throw new Error('Failed to get syncs');
  }
};

export const getSyncsWithChangelog = async (projectID: string) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(
      `${BASE_URL}/schema/${projectID}/syncs/changelogs`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );
    return response.data.syncs;
  } catch (error) {
    throw new Error('Failed to get syncs with changelogs');
  }
};

export const getChangelogs = async (projectID: string, syncID: string) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(
      `${BASE_URL}/schema/${projectID}/syncs/changelogs/${syncID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );
    return response.data.changelogs;
  } catch (error) {
    throw new Error('Failed to get changelogs');
  }
};

export const signUpUser = async (companyName: string, projectName: string) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.post(
      `${BASE_URL}/companies/create`,
      {
        companyName,
        projectName,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to create company');
  }
};

export const getProjects = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(`${BASE_URL}/projects/`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data.projects;
  } catch (error) {
    throw new Error('Failed to get projects');
  }
};

export const inviteUser = async (email: string) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.post(
      `${BASE_URL}/auth/invite`,
      {
        email,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to invite user');
  }
};

export const getUserWithCompany = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(`${BASE_URL}/auth/user/company`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data.user;
  } catch (error) {
    throw new Error('Failed to fetch user');
  }
};

export const createProject = async (projectName: string) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.post(
      `${BASE_URL}/projects/create`,
      { projectName },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data.project.id;
  } catch (error) {
    throw new Error('Failed to create project');
  }
};

export const getDashboardAnalytics = async (projectID: string) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(
      `${BASE_URL}/analytics/${projectID}/dashboard`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch dashboard analytics');
  }
};

export const searchResources = async (
  projectID: string,
  query: string
): Promise<SearchResult[]> => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(
      `${BASE_URL}/search/?project_id=${projectID}&q=${encodeURIComponent(query)}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    return response.data.results;
  } catch (error) {
    throw new Error('Failed to perform search');
  }
};
