import { useState } from 'react';
import React from 'react';

interface HamburgerMenuProps {
  projectID: string;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ projectID }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <div className="relative">
      {/* Mobile menu button */}
      <div className="fixed bottom-4 right-4 sm:hidden z-50">
        <div
          className="space-y-2 bg-white p-4 rounded-full shadow-lg"
          onClick={() => setIsNavOpen((prev) => !prev)}
        >
          <span className="block h-0.5 w-6 animate-pulse bg-gray-600"></span>
          <span className="block h-0.5 w-6 animate-pulse bg-gray-600"></span>
          <span className="block h-0.5 w-6 animate-pulse bg-gray-600"></span>
        </div>
      </div>

      {/* Mobile menu content */}
      <div className={isNavOpen ? 'showMenuNav' : 'hideMenuNav'}>
        <div
          className="CROSS-ICON absolute top-0 right-0 px-8 py-8"
          onClick={() => setIsNavOpen(false)}
        >
          <svg
            className="h-8 w-8 text-gray-600"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </div>
        <ul className="flex flex-col items-center justify-between min-h-[250px]">
          <li className="border-b border-gray-400 my-8">
            <a href={`/${projectID}/dashboard`}>Dashboard</a>
          </li>
          <li className="border-b border-gray-400 my-8">
            <a href={`/${projectID}/catalog`}>Catalog</a>
          </li>
          <li className="border-b border-gray-400 my-8">
            <a href={`/${projectID}/releases`}>Releases</a>
          </li>
        </ul>
      </div>

      <style>{`
      .hideMenuNav {
        display: none;
      }
      .showMenuNav {
        display: flex;
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background: white;
        z-index: 40;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    `}</style>
    </div>
  );
};

export default HamburgerMenu;
