import React, { useCallback, useEffect, useState } from 'react';
import { getColumns, Column } from '../api/api';
import {
  Accordion,
  AccordionItem,
  BreadcrumbItem,
  Breadcrumbs,
} from '@nextui-org/react';
import AccordionItemTitleColumn from '../components/AccordionItemTitleColumn';
import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import CommandBar from '../components/CommandBar';

const Columns: React.FC = () => {
  const { projectID, tableId } = useParams<{
    projectID: string;
    tableId: string;
  }>();
  const [searchParams] = useSearchParams();
  const datasetID = searchParams.get('datasetID');
  const datasetName = searchParams.get('datasetName');
  const tableName = searchParams.get('tableName');
  const [isCommandBarOpen, setIsCommandBarOpen] = useState(false);

  const { data: columns, error } = useQuery<Column[], Error>({
    queryKey: ['columns', tableId],
    queryFn: () => getColumns(tableId!),
  });

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
      event.preventDefault();
      setIsCommandBarOpen(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return (
    <div className="p-5 w-full h-full overflow-auto">
      <h1 className="text-2xl font-medium text-page-title">Columns</h1>
      <Breadcrumbs size="lg" className="mb-4" underline="hover">
        <BreadcrumbItem href={`/${projectID}/catalog`}>Datasets</BreadcrumbItem>
        <BreadcrumbItem
          href={`/${projectID}/datasets/${datasetID}?datasetName=${datasetName}`}
        >
          {datasetName}
        </BreadcrumbItem>
        <BreadcrumbItem
          href={`/${projectID}/tables/${tableId}`}
          className="opacity-50"
        >
          {tableName}
        </BreadcrumbItem>
      </Breadcrumbs>
      {error instanceof Error && (
        <p className="text-red-500">{error.message}</p>
      )}
      <Accordion
        className="rounded-xl px-0 bg-accordion-background"
        selectionMode="multiple"
      >
        {(columns ?? []).map((column: Column) => (
          <AccordionItem
            key={column.id}
            title={
              <AccordionItemTitleColumn
                title={column.name}
                type={column.type}
              />
            }
            aria-label={column.name}
            className="rounded-md px-4 hover:bg-accordion-item-hover"
          >
            <div className="pb-4 text-sm font-light text-accordion-text">
              {column.description}
            </div>
          </AccordionItem>
        ))}
      </Accordion>
      <CommandBar
        isOpen={isCommandBarOpen}
        onClose={() => setIsCommandBarOpen(false)}
        projectID={projectID!}
      />
    </div>
  );
};

export default Columns;
