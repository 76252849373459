import React from 'react';
import { useParams } from 'react-router-dom';

interface SidebarButtonProps {
  text: string;
  link: string;
  iconSrc: string;
}

const SidebarButton: React.FC<SidebarButtonProps> = ({
  text,
  link,
  iconSrc,
}) => {
  const { projectID } = useParams();
  return (
    <li>
      <a
        href={`/${projectID}${link}`}
        className="flex items-center p-2 text-sidebar-button-text rounded-lg hover:bg-sidebar-button-hover group"
      >
        <img
          className="h-6 w-6"
          src={`${process.env.PUBLIC_URL}/${iconSrc}`}
          alt=""
        />
        <span className="ms-3">{text}</span>
      </a>
    </li>
  );
};

export default SidebarButton;
