import React, { useMemo, useCallback, useEffect, useState } from 'react';
import DataStat from '../components/DataStat';
import BarChartCard from '../components/BarChartCard';
import MultiBarChartCard from '../components/MultiBarChartCard';
import PieChartCard from '../components/PieChartCard';
import ChangeStat from '../components/ChangeStat';
import { useQuery } from '@tanstack/react-query';
import { DashboardAnalyticsResponse, getDashboardAnalytics } from '../api/api';
import { useParams } from 'react-router-dom';
import Loading from '../components/Loading';
import CommandBar from '../components/CommandBar';

const transformNullsToEmptyArrays = <T,>(obj: T): T => {
  if (obj === null) return [] as unknown as T;
  if (typeof obj !== 'object') return obj;

  if (Array.isArray(obj)) {
    return obj.map(transformNullsToEmptyArrays) as unknown as T;
  }

  const newObj = {} as T;
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      newObj[key] = transformNullsToEmptyArrays(obj[key]);
    }
  }
  return newObj;
};

export const Dashboard: React.FC = () => {
  const { projectID } = useParams();
  const [isCommandBarOpen, setIsCommandBarOpen] = useState(false);

  const {
    data: rawData,
    error,
    isLoading,
  } = useQuery<DashboardAnalyticsResponse>({
    queryKey: ['dashboard', projectID],
    queryFn: () => getDashboardAnalytics(projectID!),
  });

  const data = useMemo(() => {
    if (rawData) {
      return transformNullsToEmptyArrays(rawData);
    }
    return null;
  }, [rawData]);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
      event.preventDefault();
      setIsCommandBarOpen(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return (
    <div className="p-5 w-full h-full overflow-auto">
      <h1 className="text-2xl mb-4 font-medium text-page-title">Dashboard</h1>
      {error instanceof Error && (
        <p className="text-red-500">{error.message}</p>
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <div className="w-full">
          {data!.userHasSync ? (
            <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-4 auto-rows-[300px]">
              <div className="w-full h-full min-w-[300px]">
                <DataStat
                  current={data!.totalDatasetCount!}
                  lastMonth={data!.pastMonthDatasetCount!}
                  name="Datasets"
                />
              </div>
              <div className="w-full h-full min-w-[300px]">
                <DataStat
                  current={data!.totalTableCount!}
                  lastMonth={data!.pastMonthTableCount!}
                  name="Tables"
                />
              </div>
              <div className="w-full h-full min-w-[300px]">
                <DataStat
                  current={data!.totalColumnCount!}
                  lastMonth={data!.pastMonthColumnCount!}
                  name="Columns"
                />
              </div>
              <div className="w-full h-full min-w-[300px]">
                <DataStat
                  current={data!.totalRowCount!}
                  lastMonth={data!.pastMonthTotalRowCount}
                  name="Rows"
                />
              </div>
              <div className="lg:col-span-2 w-full h-full min-w-[300px] lg:min-w-[600px]">
                <BarChartCard
                  name="Tables per Dataset"
                  datasetNames={data!.tableCounts!.datasetNames}
                  tableCounts={data!.tableCounts!.tableCounts}
                />
              </div>
              <div className="lg:col-span-2 w-full h-full min-w-[300px] lg:min-w-[600px]">
                <MultiBarChartCard
                  name="Avg. Table Size per Dataset"
                  datasetNames={data!.tableSizeMetric!.datasetNames}
                  avgRowCounts={data!.tableSizeMetric!.avgRowCounts!}
                  tableCounts={data!.tableSizeMetric!.tableCounts}
                />
              </div>
              <div className="w-full h-full min-w-[300px]">
                <PieChartCard
                  name="Column Type Distribution"
                  columnTypeDistribution={data!.columnTypeDistribution!}
                />
              </div>
              <div className="w-full h-full min-w-[300px]">
                <DataStat
                  current={data!.currentMonthSyncCount!}
                  lastMonth={data!.pastMonthSyncCount!}
                  name="Releases"
                />
              </div>
              <div className="lg:col-span-2 w-full h-full min-w-[300px] lg:min-w-[600px]">
                <ChangeStat
                  name="MTD Changes"
                  insertions={data!.currentMonthChangeCounts!.insert}
                  deletions={data!.currentMonthChangeCounts!.delete}
                  updates={data!.currentMonthChangeCounts!.update}
                />
              </div>
            </div>
          ) : (
            <div className="h-64 w-full flex justify-center items-center text-center text-service-account-text align-middle">
              <span>
                There is no data to display yet. Head over to the settings page
                to set up your integration.
              </span>
            </div>
          )}
        </div>
      )}
      <CommandBar
        isOpen={isCommandBarOpen}
        onClose={() => setIsCommandBarOpen(false)}
        projectID={projectID!}
      />
    </div>
  );
};

export default Dashboard;
