import React from 'react';

interface GoogleSigninButtonProps {
  handleGoogleSignIn: () => void;
}

const GoogleSigninButton: React.FC<GoogleSigninButtonProps> = ({
  handleGoogleSignIn,
}) => {
  return (
    <button
      className="inline-flex h-full w-full cursor-pointer justify-center items-center rounded-xl bg-white px-4 py-2 text-xs font-medium leading-none text-slate-600 backdrop-blur-xl hover:bg-zinc-100"
      onClick={handleGoogleSignIn}
    >
      <img
        className="w-6 h-6 mr-2"
        src="https://www.svgrepo.com/show/475656/google-color.svg"
        loading="lazy"
        alt="google logo"
      />
      <span className="text-lg">Login with Google</span>
    </button>
  );
};

export default GoogleSigninButton;
