export const classNames = {
  info: 'bg-info-background border-info-border text-info-text',
  success: 'bg-success-background border-success-border text-success-text',
  warning: 'bg-warning-background border-warning-border text-warning-text',
  error: 'bg-error-background border-error-border text-error-text',
};

export const svgPaths = {
  info: 'M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z',
  success: 'M5.64 13.36l-2.28-2.28-1.28 1.28 3.56 3.56 7.72-7.72-1.28-1.28z',
  warning:
    'M10 4.5a1 1 0 0 1 2 0v5a1 1 0 1 1-2 0V4.5zm0 8a1 1 0 1 1 2 0v.5a1 1 0 1 1-2 0v-.5z',
  error:
    'M10 1C4.48 1 0 5.48 0 11s4.48 10 10 10 10-4.48 10-10S15.52 1 10 1zm1 15H9v-2h2v2zm0-4H9V5h2v7z',
};

export const svgFillColors = {
  info: 'text-info-border',
  success: 'text-success-border',
  warning: 'text-warning-border',
  error: 'text-error-border',
};
