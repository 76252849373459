import React from 'react';

interface SidebarFooterProps {
  text: string;
  link: string;
  iconSrc: string;
}

const SidebarFooter: React.FC<SidebarFooterProps> = ({
  text,
  link,
  iconSrc,
}) => {
  return (
    <li>
      <a
        href={link}
        className="flex items-center p-2 text-sidebar-button-text rounded-full hover:bg-sidebar-button-hover group"
      >
        <img className="h-8 w-8 rounded-full" src={iconSrc} alt="" />
        <span className="ms-3 text-md text-sidebar-button-text">{text}</span>
      </a>
    </li>
  );
};

export default SidebarFooter;
